import React from "react";
import SectionHead from "../components/sections/aboutHeader";
import SectionCards from "../components/sections/aboutCards";


export default function About() {
    return (
        <>
       
       <div>
         <SectionHead/>
       </div>
       
       <div>
         <SectionCards />
       </div>
    
        </>
    );
}
