import React from 'react';
import '../../style/principal/parceiros.css';
// import centelha from "../../imgs/Centelha.svg"
// import cnpq from "../../imgs/CNPq.svg"
// import furg from "../../imgs/FURG.svg"
// import fipergs from "../../imgs/FAPERGS.svg"
// import innovatio from "../../imgs/Innovatio.svg"
// import ocentec from "../../imgs/Oceantec.svg"
// import oracle from "../../imgs/Oracle.svg"
// import minciencia from "../../imgs//Ministério da Ciência.svg"
// import ilhahub from "../../imgs/Ilhub.svg"
// import bluerio from "../../imgs/BlueRio.svg"
// import unesco from "../../imgs/UNESCO.svg"
// import ideiaz from "../../imgs/Ideiaz.svg"
// import inovativa from "../../imgs/Inovativa.svg"
// import conectastartup from "../../imgs/Conecta Startup.svg"
// import copel from "../../imgs/Copel.svg"
// import govrio from "../../imgs/Governo Rio.svg"
// import uerj from "../../imgs/UERJ.svg"
// import seas from "../../imgs/SEAS.svg"
import parceiros from "../../imgs/parceiros.jpg"


function Partners() {
  return (
      <div className="parceiros">
       <img src={parceiros} alt="" />
    </div>
  );
}

export default Partners;

