import React from "react";
import SectionContato from "../components/sections/formContato";


export default function Contato() {
    return (
        <>
       <div>
         <SectionContato />
       </div>
        </>
    );
}